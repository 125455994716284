import type { FC } from "react"
import React from "react"
import {
  Box,
  Breadcrumb,
  BreadcrumbItem as BreadcrumbItemChakra,
  BreadcrumbLink,
  Container,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react"

import Link from "common/components/Link"
import type {
  Breadcrumb as BreadcrumbType,
  BreadcrumbItem,
} from "common/constants/breadcrumb"
import { useDevice } from "common/hooks/useDevice"
import ArrowLeft from "ui/components/icons/ArrowLeft"

type Props = {
  breadcrumb: BreadcrumbType
  isWhiteLabel?: boolean
  isHiddenOnMobile?: boolean
}

const MainLayoutBreadcrumb: FC<Props> = ({
  breadcrumb,
  isWhiteLabel,
  isHiddenOnMobile = false,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  // get first element (home) and up-to last two elements
  const mobileBreadcrumb = breadcrumb.filter(
    (_, idx) => idx < 1 || idx + 3 > breadcrumb.length,
  )

  const getMobileLabel = (breadcrumbItem: BreadcrumbItem) => {
    if (breadcrumbItem.mobileLabel) return breadcrumbItem.mobileLabel

    return breadcrumbItem.label
  }

  if (breadcrumb.length < 2) return <Box h={4} />

  if (Boolean(isWhiteLabel)) return <Box h={4} />

  return (
    <Container maxW="6xl" px={{ base: 4, lg: 0 }}>
      {!isHiddenOnMobile ? (
        <Breadcrumb
          display={onLittleDevice}
          mt={4}
          separator={<Text color="gray.300">/</Text>}
        >
          {mobileBreadcrumb.length >= 2 ? (
            <BreadcrumbLink as="div" color="gray.600" fontWeight="medium">
              <Link href={mobileBreadcrumb[mobileBreadcrumb.length - 2].path}>
                <HStack isInline>
                  <Icon as={ArrowLeft} fill="gray.600" h={3} ml={1} w={3} />
                  <Text>
                    {getMobileLabel(
                      mobileBreadcrumb[mobileBreadcrumb.length - 2],
                    )}
                  </Text>
                </HStack>
              </Link>
            </BreadcrumbLink>
          ) : null}
        </Breadcrumb>
      ) : null}
      <Breadcrumb
        display={onLargeDevice}
        mt={4}
        separator={<Text color="gray.300">/</Text>}
      >
        {breadcrumb.map((breadcrumbItem, idx) => (
          <BreadcrumbItemChakra key={`breadcrumb-${idx}`}>
            {idx + 1 < breadcrumb.length ? (
              <>
                {breadcrumbItem.path ? (
                  <BreadcrumbLink as="div" color="gray.400">
                    <Link href={breadcrumbItem.path}>
                      {breadcrumbItem.label}
                    </Link>
                  </BreadcrumbLink>
                ) : (
                  <Text textStyle="md">{breadcrumbItem.label}</Text>
                )}
              </>
            ) : (
              <Text color="gray.600" fontWeight="bold" textStyle="md">
                {breadcrumbItem.label}
              </Text>
            )}
          </BreadcrumbItemChakra>
        ))}
      </Breadcrumb>
    </Container>
  )
}

export default MainLayoutBreadcrumb
